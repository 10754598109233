import React, { useEffect } from 'react';

const GPTAd = () => {
  const adId = 'div-gpt-ad-1731658475526-0'; // Unique ID for this ad slot

  useEffect(() => {
    const loadGPT = () => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          try {
            const existingSlot = window.googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === adId);
            if (existingSlot) {
              window.googletag.destroySlots([existingSlot]);
            }

            const adSlot = window.googletag
              .defineSlot('/23111733658/ca-pub-6245873721150554-tag/Test1D', [728, 90], adId)
              .addService(window.googletag.pubads());

            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.display(adId);

            const refreshInterval = setInterval(() => {
              window.googletag.pubads().refresh([adSlot]);
            }, 20000);

            return () => clearInterval(refreshInterval);

          } catch (e) {
            console.error('Error setting up GPT:', e);
          }
        });
      } else {
        console.error('GPT is not loaded or googletag.cmd is not available.');
      }
    };

    let script;
    if (window.googletag && window.googletag.apiReady) {
      loadGPT();
    } else {
      script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      script.onload = loadGPT;
      document.head.appendChild(script);
    }

    return () => {
      if (script && document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="ad-container text-center d-lg-block d-md-block d-none">
      <center>
        <div
          id={adId}
          className="gpt-ad"
          style={{ width: '728px', height: '90px', backgroundColor: '#f1f1f1' }}
        ></div>
      </center>
    </div>
  );
};

export default GPTAd;
